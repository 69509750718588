/**
 * Utility functions with no external dependencies.
 */

export const REVIEW_LIMIT_TRIAL = 1;

export function notifyToast(message, variant = 'primary', duration = 3000) {
    const alert = Object.assign(document.createElement('sl-alert'), {
      variant,
      closable: true,
      duration: duration,
      innerHTML: `${message}`
    });
    document.body.append(alert);
    return alert.toast();
  }

export function notificationForNoAccess(user) {
    if (!user.is_author && user.is_logged_in && user.subscription_type === 'no_access') {
        // Should be the dame as SubscriptionManager.REVIEW_LIMIT_TRIAL.
        if (user.accessed_count >= REVIEW_LIMIT_TRIAL) {
            notifyToast("You reached the limit of free reviews you can see. Consider a <a href='./payment.html'>subscription</a> to see more.", 'warning', 20000);
        } else {
            const remainingCount = REVIEW_LIMIT_TRIAL - user.accessed_count
            notifyToast(`You have accessed ${user.accessed_count} articles. You have ${remainingCount} left.`, 'info');
        }
    } else if (!user.is_author && user.is_logged_in && user.subscription_type !== 'no_access' && user.sub_end_date) {
        const daysLeft = user.days_left_on_subscription();
        if (daysLeft <= 3) {
            notifyToast(`Your subscription will expire in ${daysLeft} days.`, 'info');
        }
        console.log(`Your subscription will expire in ${daysLeft} days.`);
    }
}

export function replaceElementWithFile(elementId, fileName) {
    fetch(fileName)
            .then(response => response.text())
            .then(data => {
                document.getElementById(elementId).innerHTML = data;
            });
}

async function getData(url, token) {
    const response = await fetch(url, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });

    if (!response.ok) {  // Handle anythong thay's not 200
        var errorMessage;
        try {
            // Attempt decoding JSON with a message field.
            const errorData = await response.json();
            errorMessage = errorData.error;
            console.log('Error data:', errorData);
        } catch (e) {
            // Fall back to a generic error message, if no JSON or message field.
            errorMessage = response.statusText
        }
        throw new Error(errorMessage);

    }
    return await response.json();
}

async function postData(url, token, payload) {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload)
    });

    if (!response.ok) {
        var errorMessage;
        try {
            // Attempt decoding JSON with a message field.
            const errorData = await response.json();
            errorMessage = errorData.error;
            console.log('Error data:', errorData);
        } catch (e) {
            // Fall back to a generic error message, if no JSON or message field.
            errorMessage = response.statusText
        }
        throw new Error(errorMessage);
    }

    return await response.json();
}

function addGlobalEventHandler(eventName, handler) {
    const eventHandlerName = `${eventName}Handlers`;
    if (typeof window[eventHandlerName] === 'undefined') {
        window[eventHandlerName] = [];
    }
    window[eventHandlerName].push(handler);
}

// Add this function to your script
function dispatchCustomEvent(eventName, detail) {
    const event = new CustomEvent(eventName, { detail: detail });
    console.log('Dispatching custom event:', eventName, detail);
    document.dispatchEvent(event);
    const handlers = window[`${eventName}Handlers`] || [];
    handlers.forEach(handler => handler(event));
};

export function setAuthToken(token) {
    // document.cookie = `authToken=${data.token}; path=/; secure; HttpOnly; SameSite=Strict`;
    document.cookie = `authToken=${token}`;
}

function getCookie(name="authToken") {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

async function sha256(message) {
    const msgBuffer = new TextEncoder().encode(message);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
}

// This is only setup for local development.
// When hosting on PythonAnywhere, keep it empty.
export const backendUrl = '';  // http://localhost:5000

export async function pingBackend() {
    const statusCheckUrl = `${backendUrl}/api/v1/status_check`;
    console.log('Pinging backend at:', statusCheckUrl);
    try {
        const response = await fetch(statusCheckUrl);
        if (response.ok) {
            return true;
        } else {
            console.error('Failed to ping backend:', response.status);
            return false;
        }
    } catch (error) {
        console.error('Error while pinging backend:', error);
        return false; // Return false if there's an error during the fetch
    }

}

export { addGlobalEventHandler, dispatchCustomEvent, getCookie, sha256, getData, postData };
